<template>
    <div>
        <div style="padding:10px 16px">
            <div class="title">健康状况调查问卷知情同意书</div>
            <div class="content">
                <div class="contentText">请您务必仔细阅读如下内容，并在确认充分理解与同意后使用：</div>
                <div class='contentTitle'>1、问卷目的</div>
                <div class="contentText"> 《健康状况调查问卷》旨在对参与免疫功能评估者的生活环境、习惯及疾病史等背景问题进行调查，以辅助免疫组库测序分析，获得更准确的免疫力评测结果。
                </div>
                <div class='contentTitle'>2、自愿原则</div>
                <div class="contentText"> 本评测只针对18岁及以上的成人自愿开展，您需要对您提供信息的准确性负责，调查问卷的准确性依赖于您提供的相关信息。
                </div>
                <div class='contentTitle'>3、保密原则</div>
                <div class="contentText"> 您在评测中提供的任何信息，武汉康圣贝泰生物科技有限公司（以下简称：康圣贝泰）将严格保密并依法保护您的个人隐私，因您的原因所造成的个人信息外泄，康圣贝泰不承担责任。
                </div>
                <div class='contentTitle'>4、免责声明</div>
                <div class="contentText">
                    鉴于目前的科学发展水平，以及调查问卷信息有限，本免疫功能评估不能完整反映您免疫系统的能力及状态。此外，您的评测结果不可用于任何临床诊断，也不能替代任何专业医疗建议。如有需要，您应当向医生寻求有关诊断、治疗以及预防等方面的建议。
                </div>
                <div class="contentText" style="margin-top: 12px;"> 参与测评即表明您充分知悉并同意本《健康状况调查问卷知情同意书》的所有内容。
                </div>
            </div>

        </div>
        <!-- 可读+未同意————可以点击 -->
        <div v-if="(!isReadonly && !isAgree)">
            <div style="height:92px;width:100%;"></div>
            <div class="bottom">
                <button class="bottomButton" @click="agree">已阅读并同意 {{ index != 0 ? index + 's' : '' }}</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            index: 5,  //5s后能点击同意按钮
            isAgree: localStorage.getItem('agreement') == '1' ? true : false,
            isReadonly: localStorage.getItem('isReadonly') == 'false' ? false : true
        }
    },
    created() {
    },
    mounted() {
        this.timeOut()
    },
    computed: {
    },
    methods: {
        // 倒计时
        timeOut() {
            const timer = setInterval(() => {
                if (this.index > 0) {
                    this.index--
                } else {
                    clearInterval(timer)
                }
            }, 1000)
        },
        // 同意知情书
        agree() {
            if (this.index == 0) {
                localStorage.setItem('agreement', 1)
                // 返回上一页
                this.$router.back()
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.title {
    font-size: 18px;
    
    color: #000000;
    line-height: 25px;
    text-align: center;
    margin-bottom: 12px;
}

.content {
    text-align: justify;
    
}

.contentTitle {
    margin: 12px 0;
}

.contentText {
    font-size: 14px;
    color: #333333;
    text-align: justify;
}
</style>
